export default {
  createdAt: 1682472624,
  updatedAt: 1697233801,
  slug: 'rowing-pace-calculator',
  heading: 'Rowing Pace Calculator',
  title: 'Rowing Pace Calculator - Time, Distance & Watts (Dashboard)',
  description: 'Calculate your rowing 500m split by distance and time.  Includes 5 event average pace datasets.  Plan your next PR with the visual pace dashboard.',
  directory: 'calculators',
  hero: {
    heading: 'Rowing Pace Calculator',
    description: 'Determine the optimal pace, distance, or time for any rowing distance.',
    sourceSpreadsheetUrl: 'https://docs.google.com/spreadsheets/d/1WWM7GG0IBYHyJvVhnEfMvAZuRtAos1rHW3DpPWQquV8/edit?usp=sharing',
    featuredImgSrcsets: [{
      src: '/img/pages/rowing-pace-calculator/hero-small.webp 1x, /img/pages/rowing-pace-calculator/hero-small@2x.webp 2x',
      size: '(max-width: 744px)',
      aspectRatio: '784 / 380'
    }, {
      src: '/img/pages/rowing-pace-calculator/hero-medium.webp 1x, /img/pages/rowing-pace-calculator/hero-medium@2x.webp 2x',
      size: '(max-width: 1023px)',
      aspectRatio: '1200 / 642'
    }, {
      src: '/img/pages/rowing-pace-calculator/hero-large.webp 1x, /img/pages/rowing-pace-calculator/hero-large@2x.webp 2x',
      size: '(min-width: 1024px)',
      aspectRatio: '1604 / 674'
    }],
    featuredImgAlt: 'Indoor rower pulling handle to chest at the top of their stroke, surrounded by mathmatical symbols',
    features: [{
      text: 'Free'
    }, {
      text: 'No Signup'
    }, {
      text: 'Save & Share',
      tooltip: 'Your answers are auto-saved privately and securely to your local device - nowhere else. Click the link button to get a sharable url you can send.'
    }]
  },
  images: {
    resourceUrl: '/img/pages/rowing-pace-calculator/featured-image.webp',
    resourceUrlAlt: 'Indoor rower pulling handle to chest at the top of their stroke, surrounded by mathmatical symbols',
    twitterPost: '/img/pages/rowing-pace-calculator/twitter-post.webp',
    openGraph: '/img/pages/rowing-pace-calculator/og-image.webp'
  },
  icon: '/icons/calculator-rowing.svg',
  reviews: [],
  widgets: {
    primary: 'Y0mXuYaBOZ5FhGk7lF18',
    primaryStaging: 'aioJvenFxRbhRc11YGZk'
  },
  articleSections: [{
    name: 'How to Calculate Rowing Pace',
    id: 'how-to-calculate-rowing-pace'
  }, {
    name: 'What is a Good Rowing Pace?',
    id: 'what-is-a-good-rowing-pace'
  }, {
    name: '500m Rowing Times',
    id: '500m-rowing-times'
  }, {
    name: '1,000m Rowing Times',
    id: '1000m-rowing-times'
  }, {
    name: '2,000m Rowing Times',
    id: '2000m-rowing-times'
  }, {
    name: '5,000m Rowing Times',
    id: '5000m-rowing-times'
  }, {
    name: '6,000m Rowing Times',
    id: '6000m-rowing-times'
  }, {
    name: 'How to Improve Your Rowing Pace',
    id: 'how-to-improve-your-rowing-pace'
  }, {
    name: 'Conclusion',
    id: 'conclusion'
  }]
};