import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { mauricio, michael, cory } from '../../../content/reviewers';
export default {
  createdAt: 1680735689,
  updatedAt: 1697233877,
  slug: 'treadmill-pace-calculator',
  heading: 'Treadmill Pace Calculator',
  title: 'Easy Treadmill Pace Calculator: MPH/KM, Incline, & More',
  description: 'Calculate an average pace for any treadmill speed. Discover time or distance to complete a run at your pace. Includes: MPH, Kilometers, & incline adjustment.',
  directory: 'calculators',
  hero: {
    heading: 'Treadmill Pace Calculator',
    description: 'Determine the optimal pace, speed, time and distance for your treadmill workout. Then share your results.',
    sourceSpreadsheetUrl: 'https://docs.google.com/spreadsheets/d/10Ex8vpa5j0Os50XnYAMs-qgBkLJ2UB_MmsHeklZVuzc/edit?usp=sharing',
    featuredImgSrcsets: [{
      src: '/img/pages/treadmill-pace-calculator/hero-small.webp 1x, /img/pages/treadmill-pace-calculator/hero-small@2x.webp 2x',
      size: '(max-width: 744px)',
      aspectRatio: '784 / 380'
    }, {
      src: '/img/pages/treadmill-pace-calculator/hero-medium.webp 1x, /img/pages/treadmill-pace-calculator/hero-medium@2x.webp 2x',
      size: '(max-width: 1023px)',
      aspectRatio: '1200 / 642'
    }, {
      src: '/img/pages/treadmill-pace-calculator/hero-large.webp 1x, /img/pages/treadmill-pace-calculator/hero-large@2x.webp 2x',
      size: '(min-width: 1024px)',
      aspectRatio: '1604 / 674'
    }],
    featuredImgAlt: 'Speedometer next to calculations of running pace, incline, and MPH/Kilometers',
    features: [{
      text: 'Free'
    }, {
      text: 'No Signup'
    }, {
      text: 'Save & Share',
      tooltip: 'Your answers are auto-saved privately and securely to your local device - nowhere else. Click the link button to get a sharable url you can send.'
    }]
  },
  images: {
    resourceUrl: '/img/pages/treadmill-pace-calculator/featured-image.webp',
    resourceUrlAlt: 'Pie chart showing costs of major wedding budget items',
    twitterPost: '/img/pages/treadmill-pace-calculator/twitter-post.webp',
    openGraph: '/img/pages/treadmill-pace-calculator/og-image.webp'
  },
  reviews: [_objectSpread(_objectSpread({}, mauricio), {}, {
    quote: "My favorite tool for planning clients' treadmill sessions. Results are so clear & easy to share. Plus my clients get motivated to see how close they are to their goals.",
    score: 5
  }), _objectSpread(_objectSpread({}, michael), {}, {
    quote: 'My go-to tool for all my cardio workouts. Tells me exactly the metrics I need to hit to reach my fitness goals and saves my results automatically.',
    score: 5
  }), _objectSpread(_objectSpread({}, cory), {}, {
    quote: "The perfect treadmill workout assistant. I've been using it to plan all my indoor sessions building up to race day.",
    score: 5
  })],
  widgets: {
    primary: 'SIsuW3ntgNfYWLkwWrzW',
    primaryStaging: '1TVBkqIH3AgVG0h2Axe9'
  },
  articleSections: [{
    name: 'How to Calculate Treadmill Speeds',
    id: 'how-to-calculate-treadmill-speeds'
  }, {
    name: 'Using the Treadmill Time Calculator',
    id: 'using-the-treadmill-time-calculator'
  }, {
    name: 'Using the Treadmill Distance Calculator',
    id: 'using-the-treadmill-distance-calculator'
  }, {
    name: 'Treadmill Incline Conversions',
    id: 'treadmill-incline-conversions'
  }, {
    name: 'Conclusion',
    id: 'conclusion'
  }]
};